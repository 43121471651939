import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import "./TableFlotaRegionsCaletaStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TableFlotaRegions extends Component {
  static contextType = RegionDataContext;

  handleRowClick = (caletaClic) => {
    const { caleta, setCaleta } = this.context;
    if (caleta === caletaClic) {
      setCaleta("");
    } else setCaleta(caletaClic);
  };

  render() {
    const { filteredGeneralData, region, flota} = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const regionName = regionNames[region] || "Sin información disponible";

    const desembarquePorCaleta = {};
    filteredGeneralData.forEach((item) => {
      const key = item.caleta;
      if (!desembarquePorCaleta[key]) {
        desembarquePorCaleta[key] = {
          caleta: item.caleta,
          desembarque: 0,
        };
      }
      desembarquePorCaleta[key].desembarque += item.desembarque;
    });

    const totalDesembarque = Object.values(desembarquePorCaleta).reduce(
      (sum, value) => sum + value.desembarque,
      0
    );

// Función para determinar el número de decimales basados en el valor
const getDecimalPlaces = (type, value) => {
  if (value >= 1 && type==='desembarque') return 0;
  if (value >= 1 && type==='porcentage') return 1;
  const magnitude = Math.abs(Math.log10(value));
  return Math.min(6, Math.ceil(magnitude));
};

const data = Object.values(desembarquePorCaleta).map((item) => {
  const percentage = (item.desembarque / totalDesembarque) * 100;
  
  const decimalDesembarque = getDecimalPlaces( 'desembarque' , item.desembarque);
  const decimalPercentage = getDecimalPlaces('porcentage', percentage);

  return {
    caleta: item.caleta,
    desembarque: item.desembarque,
    formattedDesembarque: item.desembarque.toLocaleString("de-DE", {
      minimumFractionDigits: decimalDesembarque,
      maximumFractionDigits: decimalDesembarque,
    }),
    percentage: isNaN(percentage) ? "0" : percentage.toLocaleString("de-DE", {
      minimumFractionDigits: decimalPercentage,
      maximumFractionDigits: decimalPercentage,
    }),
  };
});


    data.sort((a, b) => b.desembarque - a.desembarque);
 //   setCaletaInfo(data);
    return (

        <TableContainer className= "table-region-caleta-flota" component={Paper}>
          <div className="header-table-region-caleta-flota">
          <div className="header-table-region-caleta-flota">
              {region === "" && flota === ""
                ? `Desembarque por caleta País`
                : region === "" && flota !== ""
                ? `Desembarque por caleta País ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  }`
                : region !== "" &&
                  region !== 999 &&
                  region !== 99 &&
                  flota === ""
                ? `Desembarque por caleta de la Región ${regionName}`
                : region !== "" && region === 999 && flota === ""
                ? `Desembarque por caleta en el Extranjero`
                : region !== "" && region === 99 && flota === ""
                ? `Desembarque por caleta en Lanchas Transportadoras`
                : region !== "" &&
                  region !== 999 &&
                  region !== 99 &&
                  flota !== ""
                ? `Desembarque por caleta ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  } de la Región ${regionName}`
                : region !== "" && region === 999 && flota !== ""
                ? `Desembarque por caleta ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  } en el Extranjero`
                : region !== "" && region === 99 && flota !== ""
                ? `Desembarque por caleta ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  } en Lanchas Transportadoras`
                : `Sin información disponible`}
            </div>
          </div>
          <Table aria-label="flota table" className="table-info-region-caleta-flota">
            <TableHead className="no-hover-title-flota">
              <TableRow className="title-column-flota">
                <TableCell className="text-title-column-flota" align="left">
                  Caleta/Puerto
                </TableCell>
                <TableCell className="text-title-column-flota" align="center">
                  Toneladas
                </TableCell>
                <TableCell className="text-title-column-flota" align="center">
                  Porcentaje
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.caleta)}
                >
                  <TableCell align="left">{row.caleta}</TableCell>
                  <TableCell align="right" className="text-data-table-flota">
                    {row.formattedDesembarque}
                  </TableCell>
                  <TableCell align="right">{row.percentage}%</TableCell>
                </TableRow>
              ))}
              <TableRow className="table-total-region-caleta-flota no-hover">
                <TableCell align="right">
                  <strong className="table-total-text-region-caleta-flota">
                    Total
                  </strong>
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-caleta-flota text-data-table"
                >
                  {totalDesembarque.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-caleta-flota"
                >
                  100%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

    );
  }
}

export default TableFlotaRegions;
