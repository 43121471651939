import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import { HomeDataContext } from '../../../../contexts/DataProviderHome';
import './ChartCircleGenaralFlotaStyle.css';

export class ChartCircleGeneralFlota extends Component {
  static contextType = HomeDataContext;

  handleChartClick = (params) => {
    const { setFlota, flota } = this.context;

    if (params.name !== flota) {
      setFlota(params.name);
    } else {
      setFlota('');
    }
  };

  render() {
    const { filteredGeneralData } = this.context;

    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por flota
    const desembarquePorFlota = {};

    // Calcular el total de desembarque para cada flota
    filteredGeneralData.forEach(item => {
      if (!desembarquePorFlota[item.flota]) {
        desembarquePorFlota[item.flota] = 0;
      }
      desembarquePorFlota[item.flota] += item.desembarque;
    });

    // Preparar datos para el gráfico
    const data = Object.keys(desembarquePorFlota).map(flota => ({
      name: flota,
      value: desembarquePorFlota[flota]
    }));

    const option = {
      color: ['#9FE080', '#5C7BD9', '#FFDC60', 'ORANGE'],
      title: {
        text: 'Flota',
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        zIndex: 100, // Aseguramos que el zIndex del tooltip sea bajo
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(',');
          return `${params.name}: ${formattedValue}`;
        }
      },
      legend: {
        show: false // Oculta la leyenda
      },
      series: [
        {
          name: 'Desembarque',
          type: 'pie',
          radius: '50%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    return (
      <div className='container-chart-general-flota'>
        <ReactECharts
          className='chart-general-flota'
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: '100%', width: '100%' }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleGeneralFlota;
