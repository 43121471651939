import React, { useContext } from "react";
import "./PlantScreenStyle.css";
import Loading from "../../components/Loading/Loading";
import { GlobalDataContext } from "../../contexts/DataProvider";
import FloatingMenuPlant from "../../components/PlantScreenComponent/FloatingMenuPlant/FloatingMenuPlant";
import TablePlantYear from "../../components/PlantScreenComponent/Tables/TablePlantYear/TablePlantYear";
import { ChartLineYearPlant } from "../../components/PlantScreenComponent/ChartPlant/ChartLineYearPlant/ChartLineYearPlant";
import TablePlantEspecie from "../../components/PlantScreenComponent/Tables/TablePlantYearEspecie/TablePlantYearEspecie";
import TablePlantReprocess from "../../components/PlantScreenComponent/Tables/TablePlantReprocess/TablePlantReprocess";
import TablePlantRegion from "../../components/PlantScreenComponent/Tables/TablePlantRegion/TablePlantRegion";
import TablePlantYearPlanta from "../../components/PlantScreenComponent/Tables/TablePlantYearPlanta/TablePlantYearPlanta";
import TablePlantYearLinea from "../../components/PlantScreenComponent/Tables/TablePlantYearLinea/TablePlantYearLinea";
import ChartCircleRegionProdPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleRegionProdPlant/ChartCircleRegionProdPlant";
import ChartCircleRegionMPPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleRegionMPPlant/ChartCircleRegionMPPlant";
import ChartCircleLineProdPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleLineProdPlant/ChartCircleLineProdPlant";
import ChartCircleLineMPPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleLineMPPlant/ChartCircleLineMPPlant";
import ChartCirclePlantProdPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCirclePlantProdPlant/ChartCirclePlantProdPlant";
import ChartCirclePlantMPPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCirclePlantMPPlant/ChartCirclePlantMPPlant";
import ChartCircleEspecieMPPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleEspecieMPPlant/ChartCircleEspecieMPPlant";
import ChartCircleEspecieProdPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleEspecieProdPlant/ChartCircleEspecieProdPlant";
import ChartCircleReprocesoMPPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleReprocesoMPPlant/ChartCircleReprocesoMPPlant";
import ChartCircleReprocesoProdPlant from "../../components/PlantScreenComponent/ChartPlant/ChartCircleReprocesoProdPlant/ChartCircleReprocesoProdPlant";
export default function PlantScreen() {
  const { isLoader } = useContext(GlobalDataContext);

  return (
    <div className="container-plant-select-screens">
      {isLoader ? (
        <Loading />
      ) : (
        <>
          <FloatingMenuPlant />

          <div className="container-plant-info">
            <div className="container-plant-1">
              <TablePlantYear />
              <ChartLineYearPlant />
            </div>
            <div className="container-plant-2">
              <TablePlantEspecie />
              <TablePlantReprocess />
            </div>
            <div className="container-plant-3">
              <TablePlantRegion />
              <TablePlantYearPlanta />
            </div>
            <div className="container-plant-4">
              <TablePlantYearLinea />
              <ChartCircleRegionProdPlant/>
            </div>
            <div className="container-plant-5">
              <ChartCircleRegionMPPlant/>
              <ChartCircleLineProdPlant/>
            </div>
            <div className="container-plant-6">
            <ChartCircleLineMPPlant/>
            <ChartCirclePlantProdPlant/>

            </div>
            <div className="container-plant-7">

            <ChartCirclePlantMPPlant/>
            <ChartCircleEspecieMPPlant/>

            </div>

            <div className="container-plant-7">

            <ChartCircleEspecieProdPlant/>
<ChartCircleReprocesoMPPlant/>
            </div>
            <div className="container-plant-7">

<ChartCircleReprocesoProdPlant/>
</div>
 
          </div>
        </>
      )}
    </div>
  );
}
