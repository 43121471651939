import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import "./TablePlantYearLineaStyle.css";

export class TablePlantYearLinea extends Component {
  static contextType = PlantDataContext;

  handleRowClick = (lineaClic) => {
    const { linea, setLinea } = this.context;
    if (linea === lineaClic) {
      setLinea("");
    } else setLinea(lineaClic);
  };

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Agrupar datos por planta y cod_linea, y sumar `m_prima` y `produccion`
    const dataByLineaCod = {};
    let totalMateriaPrima = 0;
    let totalProduccion = 0;

    filteredGeneralData.forEach((item) => {
      const key = `${item.linea}-${item.cod_linea}`;
      if (!dataByLineaCod[key]) {
        dataByLineaCod[key] = {
          linea: item.linea,
          cod_linea: item.cod_linea,
          mPrima: 0,
          produccion: 0,
        };
      }
      dataByLineaCod[key].mPrima += item.m_prima;
      dataByLineaCod[key].produccion += item.produccion;

      // Calcular totales
      totalMateriaPrima += item.m_prima;
      totalProduccion += item.produccion;
    });

    // Formatear datos para la tabla
    const data = Object.values(dataByLineaCod).map((item) => ({
      linea: item.linea,
      cod_linea: item.cod_linea,
      mPrima: item.mPrima.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      produccion: item.produccion.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));

    // Ordenar alfabéticamente por linea y cod_linea
    // Ordenar alfabéticamente por linea y cod_linea (convertido a string)
data.sort(
  (a, b) =>
    a.linea.localeCompare(b.linea) ||
    a.cod_linea.toString().localeCompare(b.cod_linea.toString())
);


    return (
      <div className="container-table-planta-planta">
        <TableContainer className="table-planta-planta" component={Paper}>
          <div className="header-table-planta-planta">
            <div className="header-table-planta-planta">
              {region
                ? `Datos por linea de la Región ${region}`
                : "Datos por linea País"}
            </div>
          </div>
          <Table aria-label="plant table" className="table-info-planta-planta">
            <TableHead className="no-hover-title">
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="center">
                  Linea
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Código Linea
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Materia Prima
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Producción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.linea)}
                >
                  <TableCell align="center">{row.linea}</TableCell>
                  <TableCell align="center">{row.cod_linea}</TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.mPrima}
                  </TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.produccion}
                  </TableCell>
                </TableRow>
              ))}
              {/* Fila de Totales */}
              <TableRow className="table-total-planta-planta no-hover">
                <TableCell colSpan={2} align="right">
                  <strong className="table-total-text-planta-planta">Total</strong>
                </TableCell>
                <TableCell align="right" className="table-total-text-planta-planta">
                  {totalMateriaPrima.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell align="right" className="table-total-text-planta-planta">
                  {totalProduccion.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default TablePlantYearLinea;
