import React, { useContext, useState } from "react";
import "./FloatingMenuStyle.css"; // Asegúrate de enlazar el archivo CSS donde defines el estilo del menú
import { RegionDataContext } from "../../../contexts/DataProviderRegion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faBrain } from "@fortawesome/free-solid-svg-icons";
import { Paper, IconButton, Typography, Collapse, Box, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
const FloatingMenu = () => {
  const {
    region,
    setRegion,
    flota,
    setFlota,
    especie,
    setEspecie,
    caleta,
    setCaleta,
    year,
    setYear,
    eslora,
    setEslora,
    setIsVisibleIA,
    setIaState,
  } = useContext(RegionDataContext);
  const [collapsed, setCollapsed] = useState(false);


  const handleIa = () => {
    setIsVisibleIA(true);
    setIaState(true);
  };
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  const handleDelete = (filter) => {
    switch (filter) {
      case "region":
        setRegion("");
        setFlota("");
        setEspecie("");
        setCaleta("");
        setYear("");
        setEslora("");
        setIsVisibleIA(false);
        break;
      case "flota":
        setFlota("");
        setIsVisibleIA(false);
        break;
      case "especie":
        setEspecie("");
        setIsVisibleIA(false);
        break;
      case "caleta":
        setCaleta("");
        setIsVisibleIA(false);
        break;
      case "year":
        setYear("");
        setIsVisibleIA(false);
        break;
      case "eslora":
        setEslora("");
        setIsVisibleIA(false);
        break;
      default:
        break;
    }
  };

  return (
  <Paper className="floating-menu-region"
      elevation={6}
      sx={{
        position: "fixed",

        zIndex: 1000,
        width: collapsed ? '50px' : { xs: '200px', md: '220px' },
        transition: "width 0.3s ease, height 0.3s ease",
        bgcolor: "white",
        border: "1px solid rgb(69, 106, 149)",
        boxShadow: "5px 5px 15px rgba(69, 106, 149, 0.5)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {/* Botón de colapsar */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        bgcolor: collapsed ? 'red' : '#153d77',
        p: 1
      }}>
        <Typography sx={{ color: 'white', flexGrow: 1, fontSize: '16px' }}>
          {!collapsed && "Filtros"}
        </Typography>
        <Tooltip title={collapsed ? "Expandir" : "Minimizar"}>
          <IconButton onClick={handleToggle} sx={{ color: 'white' }}>
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Tooltip>
      </Box>

      {/* Contenido de los filtros */}
      <Collapse in={!collapsed}>
        <Box sx={{ p: 1 }}>
          <table style={{ width: '100%' }}>
            <tbody>

              {region !== "" && (
                <tr>
                  <td>
                    Región:{" "}
                    {region === 99
                      ? "Lanchas Transportadoras"
                      : region === 999
                      ? "Extranjero"
                      : region}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete"
                      icon={faTrash}
                      onClick={() => handleDelete("region")}
                    />
                  </td>
                </tr>
              )}
              {flota !== "" && (
                <tr>
                  <td>Flota: {flota}</td>
                  <td>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete"
                      icon={faTrash}
                      onClick={() => handleDelete("flota")}
                    />
                  </td>
                </tr>
              )}
              {especie !== "" && (
                <tr>
                  <td>Especie: {especie}</td>
                  <td>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete"
                      icon={faTrash}
                      onClick={() => handleDelete("especie")}
                    />
                  </td>
                </tr>
              )}
              {caleta !== "" && (
                <tr>
                  <td>Caleta: {caleta}</td>
                  <td>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete"
                      icon={faTrash}
                      onClick={() => handleDelete("caleta")}
                    />
                  </td>
                </tr>
              )}
              {year !== "" && (
                <tr>
                  <td>Año: {year}</td>
                  <td>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete"
                      icon={faTrash}
                      onClick={() => handleDelete("year")}
                    />
                  </td>
                </tr>
              )}
              {eslora !== "" && (
                <tr>
                  <td>
                    Categoria: {eslora === "NO APLICA" ? "RECOLECTOR" : eslora}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete"
                      icon={faTrash}
                      onClick={() => handleDelete("eslora")}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="container-button" onClick={() => handleIa()}>
      <FontAwesomeIcon
                  className="icon-floating-menu-ia"
                  icon={faBrain}
                  onClick={() => handleDelete("eslora")}
                />
        <h4 className="text-ia">Consultar IA</h4>        
      </div>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default FloatingMenu;
