import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant"; 
import "./ChartCircleRegionMPPlantStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartCircleRegionMPPlant extends Component {
  static contextType = PlantDataContext;

  getHeaderTitle(region) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "")
      return "Materia Prima por Región País";

    if (region !== "" )
      return `Materia Prima por Región en la Región ${regionName}`;

  }

  handleChartClick = (params) => {
    const { setRegion, region } = this.context;
    const regionName = regionNames[params.data.regionCode] || ""; // Obtener el nombre de la región
  
    if (regionName !== region) {
      setRegion(regionName); // Usar el nombre de la región en lugar del código
    } else {
      setRegion("");
    }
  };
  

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por región
    const porduccionPorregion = {};

    // Calcular el total de desembarque para cada región
    filteredGeneralData.forEach((item) => {
      const regionCode = item.region;
      if (!porduccionPorregion[regionCode]) {
        porduccionPorregion[regionCode] = 0;
      }
      porduccionPorregion[regionCode] += item.m_prima;
    });

    // Preparar datos para el gráfico, utilizando los nombres de región y ordenados de mayor a menor
    const data = Object.keys(porduccionPorregion)
      .map((regionCode) => ({
        name: regionNames[regionCode] || "Sin información",
        value: porduccionPorregion[regionCode],
        regionCode: parseInt(regionCode),  // Guardar el código de la región aquí
      }))
      .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

    const option = {
      color: [
        "#E74C3C", 
        "#3498DB", 
        "#2ECC71", 
        "#F1C40F", 
        "#9B59B6", 
        "#1ABC9C", 
        "#FF5733", 
        "#2980B9", 
        "#D35400", 
        "#8E44AD", 
        "#C0392B", 
        "#27AE60", 
        "#F39C12", 
        "#16A085", 
        "#2C3E50", 
        "#F9E79F"
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Materia Prima",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-resource-region">
        <div className="title-text-chart-region-resource">
          {this.getHeaderTitle(region)}
        </div>

        <ReactECharts
          className="chart-general-resource-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }}
        />
      </div>
    );
  }
}

export default ChartCircleRegionMPPlant;
