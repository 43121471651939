import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import "./TablePescadoresRegionStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TablePescadoresRegion extends Component {
  static contextType = RegionDataContext;

  handleRowClick = (pescadorClic) => {
    /*const { categoria_pescador, setCategoriaPescador } = this.context;
    if (categoria_pescador === pescadorClic) {
      setCategoriaPescador("");
    } else {
      setCategoriaPescador(pescadorClic);
    }*/
  };
  getHeaderTitle(region, categoria_pescador, regionName) {
    if (region === "" && categoria_pescador === "")
      return "Operación de Pescadores por Categoría de Pescador País";
    if (region === "" && categoria_pescador !== "")
      return `Operación de Pescadores por Categoría de Pescador ${categoria_pescador}`;
    if (
      region !== "" &&
      region !== 999 &&
      region !== 99 &&
      categoria_pescador === ""
    )
      return `Operación de Pescadores por Categoría de Pescador en la Región ${regionName}`;
    if (region !== "" && region === 999 && categoria_pescador === "")
      return "Operación de Pescadores por Categoría de Pescador en el Extranjero";
    if (region !== "" && region === 99 && categoria_pescador === "")
      return "Operación de Pescadores por Categoría de Pescador en Lanchas Transportadoras";
    if (
      region !== "" &&
      region !== 999 &&
      region !== 99 &&
      categoria_pescador !== ""
    )
      return `Operación de Pescadores por Categoría de Pescador en la Región ${regionName}`;
    return "Sin información disponible";
  }

  calculateTotals(filteredFishermanData) {
    const conteoPorCategoriaPescadorYFlota = {};
  
    // Agrupar por categoria_pescador, rpa_pescador y flota
    filteredFishermanData.forEach((item) => {
      const key = `${item.categoria_pescador}_${item.rpa_pescador}_${item.flota}`;
  
      // Evitar duplicados por rpa_pescador dentro de la misma flota
      if (!conteoPorCategoriaPescadorYFlota[key]) {
        conteoPorCategoriaPescadorYFlota[key] = {
          categoria_pescador: item.categoria_pescador,
          flota: item.flota,
        };
      }
    });
  
    // Contar cuántos rpa_pescador hay por cada categoria_pescador y flota
    const conteoFinalPorCategoriaPescadorYFlota = {};
    Object.values(conteoPorCategoriaPescadorYFlota).forEach((entry) => {
      const { categoria_pescador, flota } = entry;
      const key = `${categoria_pescador}_${flota}`;
      if (!conteoFinalPorCategoriaPescadorYFlota[key]) {
        conteoFinalPorCategoriaPescadorYFlota[key] = {
          conteopescadores: 0,
          flota: flota,
        };
      }
      conteoFinalPorCategoriaPescadorYFlota[key].conteopescadores += 1;
    });
  
    // Crear el array de resultados
    let data = Object.keys(conteoFinalPorCategoriaPescadorYFlota).map((key) => {
      const { conteopescadores, flota } =
        conteoFinalPorCategoriaPescadorYFlota[key];
      return {
        categoria_pescador: key.split("_")[0],
        flota,
        conteopescadores: conteopescadores.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      };
    });
  
    // Definir el orden de las flotas y las categorías dentro de la flota artesanal
    const ordenFlotas = ["ARTESANAL", "RECOLECTOR", "INDUSTRIAL", "FABRICA"];
    const ordenArtesanal = ["ARMADOR", "BUZO"];
  
    // Ordenar los datos
    data = data.sort((a, b) => {
      // Comparar por flota según el orden establecido
      const flotaA = ordenFlotas.indexOf(a.flota.toUpperCase());
      const flotaB = ordenFlotas.indexOf(b.flota.toUpperCase());
      if (flotaA !== flotaB) return flotaA - flotaB;
  
      // Si ambos son de la flota artesanal, ordenar por categoría de pescador
      if (a.flota.toUpperCase() === "ARTESANAL") {
        const categoriaA = ordenArtesanal.indexOf(a.categoria_pescador.toUpperCase());
        const categoriaB = ordenArtesanal.indexOf(b.categoria_pescador.toUpperCase());
        return categoriaA - categoriaB;
      }
  
      return 0; // Si las flotas son iguales y no son artesanales, no se reordena
    });
  
    return { data };
  }
  

  render() {
    const { filteredFishermanData, region, categoria_pescador } = this.context;
    const regionName = regionNames[region] || "Sin información disponible";

    if (!filteredFishermanData || filteredFishermanData.length === 0) {
      return (
        <div className="container-table-region-pescador-categoria-region">
          <TableContainer className="table-region-pescador-categoria-region" component={Paper}>
            <div className="header-table-region-pescador-categoria-region">
              <div className="header-table--region-pescador-categoria-region">
                {this.getHeaderTitle(region, categoria_pescador, regionName)}
              </div>
            </div>
            <Table
              aria-label="pescador table"
              className="table-info-region-pescador-categoria-region"
            >
              <TableHead>
                <TableRow className="title-column-region-pescador-categoria-region">
                  <TableCell className="text-title-column-region-pescador-categoria-region" align="left">
                    Información
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left">{"Sin pescadores"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }

    const { data } = this.calculateTotals(filteredFishermanData);

    return (
      <TableContainer className="table-region-pescador-categoria-region" component={Paper}>
        <div className="header-table-region-pescador-categoria-region">
          <div className="header-table-region-pescador-categoria-region">
            {this.getHeaderTitle(region, categoria_pescador, regionName)}
          </div>
        </div>
        <Table
          aria-label="pescador table"
          className="table-info-region-pescador-categoria-region"
        >
          <TableHead>
            <TableRow className="title-column">
              <TableCell className="text-title-column" align="center">
                Flota
              </TableCell>
              <TableCell className="text-title-column" align="center">
                Categoría de Pescador
              </TableCell>

              <TableCell className="text-title-column" align="center">
                Número de pescadores
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => this.handleRowClick(row.categoria_pescador)}
              >
                <TableCell align="center">{row.flota}</TableCell>
                <TableCell align="left">{row.categoria_pescador}</TableCell>
                <TableCell align="right">{row.conteopescadores}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default TablePescadoresRegion;
