import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { isMobile } from 'react-device-detect';
import './MapRegionsStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { RegionDataContext } from '../../../contexts/DataProviderRegion';

// Función para convertir números romanos a arábigos
function romanToArabic(roman) {
  const romanToArabicMap = {
    'I': 1, 'IV': 4, 'V': 5, 'IX': 9, 'X': 10, 'XL': 40, 'L': 50, 'XC': 90,
    'C': 100, 'CD': 400, 'D': 500, 'CM': 900, 'M': 1000
  };

  let arabic = 0;
  let i = 0;

  while (i < roman.length) {
    const twoChar = roman.slice(i, i + 2);
    const oneChar = roman.slice(i, i + 1);

    if (romanToArabicMap[twoChar]) {
      arabic += romanToArabicMap[twoChar];
      i += 2;
    } else if (romanToArabicMap[oneChar]) {
      arabic += romanToArabicMap[oneChar];
      i += 1;
    }
  }

  return arabic;
}

export default class MapRegions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      selectedRegion: '' ,// Nueva variable para manejar la selección
      zoomLevel: 1.2, // Nuevo estado para manejar el zoom dinámico
    };
  }
  static contextType = RegionDataContext;
  componentDidMount() {
    const chileGeoJson = require('../../../data/regiones.json');
    echarts.registerMap('Chile', chileGeoJson);
    this.setState({ mapLoaded: true });
    this.handleResize(); // Setea el zoom inicial
    window.addEventListener('resize', this.handleResize); // Escucha cambios en el tamaño de pantalla
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize); // Limpia el listener al desmontar
  }

  handleResize = () => {
    const screenWidth = window.innerWidth;
  
    let newZoom = 1.5; // Default escritorio
  
    if (screenWidth < 1070) {
      newZoom = 1.5; // Tablets
    }
    if (screenWidth < 992) {
      newZoom = 1.2; // Tablets
    }
  

    if (screenWidth < 400) {
      newZoom = 1.5; // Móviles pequeños
    }
  
    this.setState({ zoomLevel: newZoom });
  };
  
  handleRegionClick = (params) => {
    const { regionScreenRegion, setRegion } = this.context;
    const regionName = params.name;
  
    let regionNumber = '';
  
    if (regionName === 'Lanchas Transportadoras') {
      regionNumber = 99;
    } else if (regionName === 'Extranjero') {
      regionNumber = 999;
    } else {
      const match = regionName.match(/\(([^)]+)\)/);
      if (match) {
        regionNumber = romanToArabic(match[1]);
      }
    }
  
    // Actualizar el estado selectedRegion: si la región seleccionada es la misma, deselecciona
    this.setState(prevState => ({
      selectedRegion: prevState.selectedRegion === regionName ? '' : regionName
    }));
  
    if (isMobile) {
      setRegion(regionNumber);
    } else {
      if (regionNumber !== regionScreenRegion) {
        setRegion(regionNumber);
      } else {
        setRegion('');
      }
    }
  };
  
  

  render() {
    const { mapLoaded, selectedRegion , zoomLevel} = this.state;

    if (!mapLoaded) {
      return <div>No hay datos disponibles.</div>;
    }

    const regions = [
      'Tarapacá (I)', 'Antofagasta (II)', 'Atacama (III)', 'Coquimbo (IV)', 'Valparaíso (V)', 
      'Libertador Bernardo O\'Higgins (VI)', 'Maule (VII)', 'Bío-Bío (VIII)', 'La Araucanía (IX)', 
      'Los Lagos (X)', 'Aysén del Gral.Ibañez del Campo (XI)', 'Magallanes y Antártica Chilena (XII)', 
      'Metropolitana de Santiago (XIII)', 'Los Ríos (XIV)', 'Arica y Parinacota (XV)', 'Ñuble (XVI)', 
      'Lanchas Transportadoras', 'Extranjero'
    ];

    const mapData = regions.map(region => ({
      name: region,
      value: selectedRegion === region ? 1 : 0
    }));

    const option = {
      visualMap: {
        show: false,
        min: 0,
        max: 1,
        inRange: {
          color: [
            '#F0F8FF',
            '#B0E0E6',
            '#ADD8E6',
            '#87CEEB',
            '#87CEFA',
            '#00BFFF',
            '#7EC8E3',
            '#6495ED',
            '#4682B4',
            '#4169E1',
            '#0000FF',
            '#0000CD',
          ]
        },
        text: ['Max', 'Min'],
        calculable: true,
        textStyle: {
          color: 'black'
        },
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (params) {
          return `${params.name}`;
        }
      },
      series: [
        {
          name: 'Regiones',
          type: 'map',
          roam: false,
          map: 'Chile',
          center: [-72, -36.675],
          zoom:   zoomLevel, 
          emphasis: {
            label: {
              show: true,
              color: 'green'
            },
            itemStyle: {
              areaColor: 'red',
              borderColor: 'black'
            }
          },
          data: mapData
        }
      ]
    };

    return (
      <div className='container-map-region'>
        <h2 className='title-map'>Región de desembarque</h2>
        <ReactECharts
          className='map-region'
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{
            click: this.handleRegionClick,
          }}
        />
        
        {/* Lanchas Transportadoras */}
        <div className={selectedRegion === "Lanchas Transportadoras" ? "select-container-other-region" : 'container-other-region'} 
             onClick={() => this.handleRegionClick({ name: "Lanchas Transportadoras" })}>
          <FontAwesomeIcon className='logo-other-region' icon={faLocationDot} />
          <h4 className='text-other-region'>Lanchas Transportadoras</h4>
        </div>
    
        {/* Extranjero */}
        <div className={selectedRegion === "Extranjero" ? "select-container-other-region" : 'container-other-region'} 
             onClick={() => this.handleRegionClick({ name: "Extranjero" })}>
          <FontAwesomeIcon className='logo-other-region' icon={faLocationDot} />
          <h4 className='text-other-region'>Extranjero</h4>
        </div>
      </div>
    );
  }
}
