// DataProviderHome.js
import React, { useState, useEffect, useContext } from 'react';
import { GlobalDataContext } from './DataProvider';  // Importa el contexto, no el componente

export const HomeDataContext = React.createContext();  // Renombra este contexto para evitar colisiones

const DataProviderHome = (props) => {
  const [region, setRegion] = useState('');
  const [flota, setFlota] = useState('');   
  const [generalData, setGeneralData] = useState([]);
  const [filteredGeneralData, setFilteredGeneralData] = useState([]); 
  const { setIsLoader } = useContext(GlobalDataContext);  // Usa el contexto correcto

  useEffect(() => {
    setIsLoader(true);
    const fetchData = async () => {
      console.log(`${process.env.REACT_APP_BACKEND_SERVER}`)
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/general`);

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setGeneralData(jsonData);
        setFilteredGeneralData(jsonData);
        console.log(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoader(false);
    };

    fetchData(); 
    // eslint-disable-next-line
  }, []); 

  useEffect(() => {
    const applyFilters = () => {
      let filteredData = [...generalData];

      if (region !== '') {
        filteredData = filteredData.filter(item => item.nombre_region === region);
      }

      if (flota !== '') {
        filteredData = filteredData.filter(item => item.flota === flota);
      }

      if (filteredData.length === 0) {
        setFilteredGeneralData([
          {
            "flota": flota,
            "year": 0,
            "region_desembarque": 0,
            "nombre_region": region,
            "especie": "SIN DESEMBARQUE",
            "desembarque": 0.0000001
          }
        ]);
      } else {
        setFilteredGeneralData(filteredData);
      }
    };
  
    applyFilters();
    // eslint-disable-next-line
  }, [region, flota, generalData]);
 
  return (
    <HomeDataContext.Provider
      value={{
        region,
        setRegion,
        generalData,
        filteredGeneralData,
        flota, 
        setFlota,
      
      }}
    >
      {props.children}
    </HomeDataContext.Provider>
  );
};

export default DataProviderHome;
