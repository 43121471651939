import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import "./ChartCircleLineProdPlantStyle.css";

export class ChartCircleLineProdPlant extends Component {
  static contextType = PlantDataContext;

  getHeaderTitle(linea) {
    if (linea === "") return "Porducción por Línea País";
    if (linea !== "") return `Porducción por Línea: ${linea}`;
  }

  handleChartClick = (params) => {
    const { setLinea, linea } = this.context;
    const clickedLinea = params.name; // Nombre de la línea seleccionada

    if (clickedLinea !== linea) {
      setLinea(clickedLinea); // Actualiza la línea seleccionada en el contexto
    } else {
      setLinea("");
    }
  };

  render() {
    const { filteredGeneralData, linea } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar la producción por línea
    const produccionPorLinea = {};

    // Calcular el total de producción para cada línea
    filteredGeneralData.forEach((item) => {
      const lineaName = item.linea; // Agrupamos por 'linea' en lugar de 'region'
      if (!produccionPorLinea[lineaName]) {
        produccionPorLinea[lineaName] = 0;
      }
      produccionPorLinea[lineaName] += item.produccion;
    });

    // Preparar datos para el gráfico, ordenados de mayor a menor
    const data = Object.keys(produccionPorLinea)
      .map((lineaName) => ({
        name: lineaName || "Sin información", // Nombre de la línea o "Sin información"
        value: produccionPorLinea[lineaName],
      }))
      .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

    const option = {
      color: [
        "#E74C3C",
        "#3498DB",
        "#2ECC71",
        "#F1C40F",
        "#9B59B6",
        "#1ABC9C",
        "#FF5733",
        "#2980B9",
        "#D35400",
        "#8E44AD",
        "#C0392B",
        "#27AE60",
        "#F39C12",
        "#16A085",
        "#2C3E50",
        "#F9E79F",
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Produccion",
          type: "pie", // Sigue siendo un gráfico de torta
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-resource-region">
        <div className="title-text-chart-region-resource">
          {this.getHeaderTitle(linea)}
        </div>

        <ReactECharts
          className="chart-general-resource-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }}
        />
      </div>
    );
  }
}

export default ChartCircleLineProdPlant;
