import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import "./ChartCircleReprocesoProdPlantStyle.css";

export class ChartCircleReprocesoProdPlant extends Component {
  static contextType = PlantDataContext;

  getHeaderTitle(reproceso) {
    return reproceso === "" ? "Producción por categoria de materia prima (desde reproceso)" : `Producción desde materia prima ${reproceso==='R'?'reprocesada':'directa (no obtenida desde reproceso)'}`;
  }

  handleChartClick = (params) => {
    const { setReproceso, reproceso } = this.context;
    const clickedReproceso =  params.data.name ==="SI"  ?  "R": params.data.name ==="NO" ?"F"  : "DESCONOCIDO";
console.log(clickedReproceso)
    // Si se hace clic en el mismo reproceso, lo deselecciona
    setReproceso(reproceso === clickedReproceso ? "" : clickedReproceso);
  };

  render() {
    const { filteredGeneralData, reproceso } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Mapeo de valores de reproceso
    const formatReproceso = (value) => {
      return value === "R" ? "SI" : value === "F" ? "NO" : "DESCONOCIDO";
    };

    // Agrupar la materia prima por tipo de reproceso
    const produccionPorReproceso = {};
    filteredGeneralData.forEach(({ reproceso, produccion }) => {
      const key = formatReproceso(reproceso);
      produccionPorReproceso[key] = (produccionPorReproceso[key] || 0) + produccion;
    });

    // Preparar datos para el gráfico, ordenados de mayor a menor
    const data = Object.entries(produccionPorReproceso)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value);

    const option = {
      color: [
        "#E74C3C", "#3498DB", "#2ECC71", "#F1C40F", "#9B59B6", "#1ABC9C", "#FF5733",
        "#2980B9", "#D35400", "#8E44AD", "#C0392B", "#27AE60", "#F39C12", "#16A085", "#2C3E50", "#F9E79F"
      ],
      tooltip: {
        trigger: "item",
        formatter: ({ name, value }) => {
          if (isNaN(value)) value = 0;
          const roundedValue = (Math.round(value * 10) / 10).toLocaleString("es-ES");
          return `${name}: ${roundedValue}`;
        },
      },
      legend: { show: false },
      series: [
        {
          name: "Materia Prima",
          type: "pie",
          radius: "50%",
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-resource-region">
        <div className="title-text-chart-region-resource">
          {this.getHeaderTitle(reproceso)}
        </div>
        <ReactECharts
          className="chart-general-resource-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }}
        />
      </div>
    );
  }
}

export default ChartCircleReprocesoProdPlant;
