import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota"; 
import { isMobile } from "react-device-detect";
import "./ChartLineRegionFlotaStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartLineRegionFlota extends Component {
  static contextType = FlotaDataContext;

  handleRegionChange = (selectedRegionName) => {
    // Obtiene el número de la región a partir del nombre seleccionado
    const selectedRegion = Object.keys(regionNames).find(
      key => regionNames[key] === selectedRegionName
    );

    // Actualiza la región en el contexto
    if (this.context.region === selectedRegion) {
      this.context.setRegion("");
    } else {
      this.context.setRegion(selectedRegion || "");
    }
  };

  getHeaderTitle(region) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "")
      return "Desembarque por Año, Región País";
    if (region !== "" && region !== 999 && region !== 99)
      return `Desembarque por Año, Región ${regionName}`;
    if (region === 999)
      return "Desembarque por Año, Flota en el Extranjero";
    if (region === 99)
      return "Desembarque por Año, Flota en Lanchas Transportadoras";

    return "Sin información disponible";
  }

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const regions = {};
    const yearsSet = new Set(); // Set para almacenar años únicos

    filteredGeneralData.forEach((item) => {
      if (!regions[item.region_desembarque]) {
        regions[item.region_desembarque] = {};
      }
      if (!regions[item.region_desembarque][item.year]) {
        regions[item.region_desembarque][item.year] = 0;
      }

      // Suma de desembarques
      regions[item.region_desembarque][item.year] += item.desembarque;
      yearsSet.add(item.year); // Añade el año al Set de años únicos
    });

    const years = Array.from(yearsSet).sort(); // Convierte el Set en Array y lo ordena

    const seriesData = Object.keys(regions).map((regionKey) => ({
      name: regionNames[regionKey] || regionKey,
      type: "line",
      data: years.map((year) => regions[regionKey][year] || 0), // Asegura que haya datos para todos los años, si no, 0
    }));

    const formatChileanNumber = (value) => {
      if (isNaN(value)) return 0;
      const roundedValue = Math.round(value * 10) / 10;
      const parts = roundedValue.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    };

    const option = {
    
      color: [
        "#E74C3C", 
        "#3498DB", 
        "#2ECC71", 
        "#F1C40F", 
        "#9B59B6", 
        "#1ABC9C", 
        "#FF5733", 
        "#2980B9", 
        "#D35400", 
        "#8E44AD", 
        "#C0392B", 
        "#27AE60", 
        "#F39C12", 
        "#16A085", 
        "#2C3E50", 
        "#F9E79F"
      ],
      
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let tooltipText = `${params[0].name}<br/>`;
          let total = 0;

          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${formatChileanNumber(param.value)}<br/>`;
            total += param.value;
          });

          // Validación para evitar errores en la suma total
          if (!isNaN(total)) {
            tooltipText += `<strong>Total: ${formatChileanNumber(total)}</strong>`;
          } else {
            console.error("Total no válido en tooltip:", total);
          }

          return tooltipText;
        },
      },
      legend: {
        data: Object.keys(regions).map(key => regionNames[key] || key),
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: isMobile ? 90 : 0,
        },
        data: years, // Usar los años únicos
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: formatChileanNumber,
        },
      },
      series: seriesData,
    };

    return (
      <div className="container-chart-general-line-region-flota">
        <div className="title-text-chart-year-region-flota">
          {this.getHeaderTitle(region)}
        </div>
        <ReactECharts
          className="chart-general-region-flota"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          style={{ height: "87%", width: "100%", minHeight: "300px" }}
          onEvents={{
            legendselectchanged: (params) => {
              this.handleRegionChange(params.name); // Actualiza la región seleccionada
            },
          }}
        />
      </div>
    );
  }
}

export default ChartLineRegionFlota;
