import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import "./ChartCircleEspecieMPPlantStyle.css";

export class ChartCircleEspecieMPPlant extends Component {
  static contextType = PlantDataContext;

  getHeaderTitle(especie) {
    return especie === "" ? "Materia Prima por Especie País" : `Materia Prima para la Especie ${especie}`;
  }

  handleChartClick = (params) => {
    const { setEspecie, especie } = this.context;
    if (params.data.name !== especie) {
      setEspecie(params.data.name);
    } else {
      setEspecie("");
    }
  };

  render() {
    const { filteredGeneralData, especie } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por especie
    const porduccionPorespecie = {};

    // Calcular el total de materia prima para cada especie
    filteredGeneralData.forEach((item) => {
      const especie = item.especie;
      if (!porduccionPorespecie[especie]) {
        porduccionPorespecie[especie] = 0;
      }
      porduccionPorespecie[especie] += item.m_prima;
    });

    // Preparar datos para el gráfico, ordenados de mayor a menor
    const data = Object.keys(porduccionPorespecie)
      .map((especie) => ({
        name: especie,
        value: porduccionPorespecie[especie],
      }))
      .sort((a, b) => b.value - a.value);

    const option = {
      color: [
        "#E74C3C", "#3498DB", "#2ECC71", "#F1C40F", "#9B59B6", "#1ABC9C", "#FF5733", "#2980B9", "#D35400", "#8E44AD", "#C0392B", "#27AE60", "#F39C12", "#16A085", "#2C3E50", "#F9E79F"
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return `${params.name}: ${parts.join(",")}`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Materia Prima",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-resource-region">
        <div className="title-text-chart-region-resource">
          {this.getHeaderTitle(especie)}
        </div>

        <ReactECharts
          className="chart-general-resource-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }}
        />
      </div>
    );
  }
}

export default ChartCircleEspecieMPPlant;
