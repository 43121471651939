import React, { useContext, useState, useEffect } from "react";
import { HomeDataContext } from '../../../contexts/DataProviderHome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import './FloatingMenuHomeStyle.css';
import { Paper, IconButton, Typography, Collapse, Box, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const FloatingMenuHome = () => {
  const [scroll, setScroll] = useState(0);
  const { region, setRegion, flota, setFlota } = useContext(HomeDataContext);
  const [collapsed, setCollapsed] = useState(false);

  const handleDelete = (filter) => {
    switch (filter) {
      case "region":
        setRegion("");
        break;
      case "flota":
        setFlota("");
        break;
      default:
        console.warn(`Filtro desconocido: ${filter}`);
    }
  };

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const getHeight = () => {
    if (collapsed === true) return '50px';
    if (region === '' && flota === '') return '165px';
    if (region === '' && flota !== '') return '165px';
    if (region !== '' && flota === '') return '165px';
    if (region !== '' && flota !== '') return '200px';
    return '0px';
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScroll(scrollPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getPosition = () => {
    const screenWidth = window.innerWidth;
  
    let limitTop = 580;
    let limitBottom = 1368;
    let paddingLeft = 35;


    if (screenWidth < 494) {
      limitTop = 555;
      limitBottom = 2050;
      paddingLeft = 5;
    } else    if (screenWidth < 768) {
      limitTop = 480;
      limitBottom = 2050;
      paddingLeft = 5;
    } else if (screenWidth < 1070) {
      limitTop = 570;
      limitBottom =2030;
      paddingLeft = 5;
    }
    else if (screenWidth < 1500) {
      limitTop = 550;
      limitBottom =1400;
      paddingLeft = 5;
    }  else {
      limitTop = 530;
      limitBottom = 1050;
      paddingLeft = 45;
    }
  
    let topOffset;
    if (scroll < limitTop) {
      topOffset = `${limitTop - scroll}px`;
    } else if (scroll > limitBottom) {
      topOffset = `${limitBottom - scroll}px`;
    } else {
      topOffset = '0px';
    }
  
    return {
      top: topOffset,
      left: `${paddingLeft}px`
    };
  };
  
  
  
  const { top: topOffset, left: leftOffset } = getPosition();

  return (
    <Paper
      elevation={6}
      sx={{
        position: "fixed",
        top: topOffset,        // 👈 Renombrado para evitar conflicto
        left: leftOffset,      // 👈 Asegurándote que se use el valor retornado
        zIndex: 1000,
        width: collapsed ? '50px' : { xs: '200px', md: '220px' },
        transition: "width 0.3s ease, height 0.3s ease",
        height: getHeight(),
        bgcolor: "white",
        border: "1px solid rgb(69, 106, 149)",
        boxShadow: "5px 5px 15px rgba(69, 106, 149, 0.5)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {/* Botón de colapsar */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        bgcolor: collapsed ? 'red' : '#153d77',
        p: 1
      }}>
        <Typography sx={{ color: 'white', flexGrow: 1, fontSize: '16px' }}>
          {!collapsed && "Filtros"}
        </Typography>
        <Tooltip title={collapsed ? "Expandir" : "Minimizar"}>
          <IconButton onClick={handleToggle} sx={{ color: 'white' }}>
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Tooltip>
      </Box>

      {/* Contenido de los filtros */}
      <Collapse in={!collapsed}>
        <Box sx={{ p: 1 }}>
          <table style={{ width: '100%' }}>
            <tbody>
              {region !== "" && (
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                    Región: {region}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete-home"
                      icon={faTrash}
                      onClick={() => handleDelete("region")}
                      style={{ cursor: 'pointer', color: 'blue' }}
                    />
                  </td>
                </tr>
              )}
              {flota !== "" && (
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                    Flota: {flota}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      className="icon-floating-menu-delete-home"
                      icon={faTrash}
                      onClick={() => handleDelete("flota")}
                      style={{ cursor: 'pointer', color: 'blue' }}
                    />
                  </td>
                </tr>
              )}
              {(region === "" && flota === "") && (
                <tr>
                  <td colSpan={2} style={{ textAlign: 'center', padding: '8px', color: '#999' }}>
                    Sin filtros activos
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default FloatingMenuHome;
