import React, { useContext } from "react";
import "./FloatingMenuPlantStyle.css"; // Asegúrate de enlazar el archivo CSS donde defines el estilo del menú
import { PlantDataContext } from "../../../contexts/DataProviderPlant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const FloatingMenuPlant = () => {
  // Accede al contexto usando el hook useContext
  let {
    region,
    setRegion,
    year,
    setYear,
    planta,
    setPlanta,
    linea,
    setLinea,
    especie,
    setEspecie,
    reproceso,
    setReproceso,
    setIsVisibleIA,

   

  } = useContext(PlantDataContext);

  // Función para manejar la eliminación de filtros
  const handleDelete = (filter) => {
    switch (filter) {
      
        case "planta":
          setPlanta("");
          setIsVisibleIA(false); // Se oculta IA al borrar flota
          break;
      case "region":
        setRegion("");
        setIsVisibleIA(false); // Se oculta IA al borrar flota
        break;
      case "especie":
        setEspecie("");
        setIsVisibleIA(false); // Se oculta IA al borrar especie
        break;
      case "year":
        setYear("");
        setIsVisibleIA(false); // Se oculta IA al borrar año
        break;
        case "linea":
          setLinea("");
          setIsVisibleIA(false); // Se oculta IA al borrar año
          break;  
          case "reproceso":
            setReproceso("");
            setIsVisibleIA(false); // Se oculta IA al borrar año
            break;  
      default:
        break;
    }
  };


  return (
    <div className="floating-menu">
      <div className="container-title-menu-floating">Filtros Aplicados</div>

      <table>
        <tbody>

        {planta !== "" && (
            <tr>
              <td>Planta: {planta}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("planta")}
                />
              </td>
            </tr>
          )}

          {region !== "" && (
            <tr>
              <td>
                Región:{" "}
                {region === 99
                  ? "Lanchas Transportadoras"
                  : region === 999
                  ? "Extranjero"
                  : region}
              </td>

              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("region")}
                />
              </td>
            </tr>
          )}

          {especie !== "" && (
            <tr>
              <td>Especie: {especie}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("especie")}
                />
              </td>
            </tr>
          )}

          {year !== "" && (
            <tr>
              <td>Año: {year}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("year")}
                />
              </td>
            </tr>
          )}
                    {linea !== "" && (
            <tr>
              <td>Linea: {linea}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("linea")}
                />
              </td>
            </tr>
          )}
                             {reproceso !== "" && (
            <tr>
              <td>Reproceso: { reproceso=== "R" ? "SI" : reproceso=== "F" ? "NO" : "DESCONOCIDO"}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("reproceso")}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>

    </div>
  );
};

export default FloatingMenuPlant;
