import React, { useContext } from "react";
import "./HomeScreenStyle.css"; // Importamos los estilos
import Loading from "../../components/Loading/Loading";
import { GlobalDataContext } from "../../contexts/DataProvider";
import MapGeneral from "../../components/HomeScreenComponent/MapGeneral/MapGeneral";
import ChartLineFlota from "../../components/HomeScreenComponent/Charts/ChartLineFlota/ChartLineFlota";
import ChartCircleGeneralFlota from "../../components/HomeScreenComponent/Charts/ChartCircleFlota/ChartCircleGeneralFlota";
import TableFlota from "../../components/HomeScreenComponent/Tables/TableFlota/TableFlota";
import FloatingMenuHome from "../../components/HomeScreenComponent/FloatingMenuHome/FloatingMenuHome";
const Home = () => {
  const { isLoader } = useContext(GlobalDataContext); // Aquí usamos el hook useContext

  return (
    <div className="home-screen">
      {isLoader ? (
        <Loading />
      ) : (
        <div className="home-screen__content">
          <div className="home-screen__intro box-style">
            <h1 className="home-screen__intro-title">
              Analiza la Pesca en Chile
            </h1>
            <p className="home-screen__intro-subtitle">
              Explora datos actualizados sobre desembarques, especies y flotas a
              través de gráficos interactivos, tablas dinámicas y análisis con
              inteligencia artificial.
            </p>
          </div>

          <div className="home-screen__demo">

              <FloatingMenuHome />
    
            <div className="home-screen__demo-container">

            <div className="home-screen__demo-chart-container">
                <div className="home-screen__demo-title  box-style">
                  <h2>Demostración Interactiva del Sistema</h2>
                </div>
                <div className="home-screen__demo-content">
                  <MapGeneral />

                  <div className="home-screen__charts-section">
                    <div className="home-screen__charts-group">
                      <ChartCircleGeneralFlota />
                      <TableFlota />
                    </div>
                    <div className="ChartLineFlota-Home-desktop">
                      {" "}
                      <ChartLineFlota />
                    </div>
                  </div>
                </div>

                <div className="ChartLineFlota-Home-table">
                  {" "}
                  <ChartLineFlota />
                </div>
              </div>

            </div>
          </div>
          <section className="overview">
            <h2>Datos de Desembarques en Chile</h2>
            <p>
              Nuestra plataforma analiza los datos de desembarques de manera
              detallada, permitiéndote visualizar el impacto de diversas
              variables como:
            </p>
            <ul className="overview-list">
              <li>Región de Desembarque</li>
              <li>Especies Capturadas</li>
              <li>Flota Pesquera (artesanal e industrial)</li>
              <li>Año y Temporada</li>
              <li>Puertos y Caletas</li>
            </ul>
          </section>
          {/* Data Insights Section */}
          <section className="data-insights">
            <h2>Principales Indicadores</h2>
            <div className="insight-cards">
              <div className="insight-card">
                <h3>Análisis Específico por Región y Caletas</h3>
                <p>
                  Descubre un análisis detallado de las regiones de pesca en
                  Chile, explorando los desembarques por Caletas y Puertos, y
                  sus respectivas contribuciones al total de desembarques.
                </p>
              </div>

              <div className="insight-card">
                <h3>Análisis de Recursos Pesqueros</h3>
                <p>
                  Realiza un análisis detallado de cada uno de los recursos
                  pesqueros, explorando el volumen de captura a lo largo de los
                  años, así como su concentración de desembarques por regiones y
                  flota.
                </p>
              </div>

              <div className="insight-card">
                <h3>Evolución de la Pesca en Chile</h3>
                <p>
                  Descubre cómo ha cambiado la pesca en Chile a lo largo del
                  tiempo con gráficos de evolución por año.
                </p>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Home;
