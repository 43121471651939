import React, { useContext } from "react";
import "./ResourcesScreenStyle.css";
import AlphabetBar from "../../components/ResourceScreenComponent/AlphabetBar/AlphabetBar";
import ListResource from "../../components/ResourceScreenComponent/ListResourse/ListResource";
import { GlobalDataContext } from "../../contexts/DataProvider";
import { ResourceDataContext } from "../../contexts/DataProviderResource"; // Asegúrate de que sea ResourceDataContext
import Loading from "../../components/Loading/Loading";
import TableFlotaResources from "../../components/ResourceScreenComponent/Tables/TableFlotaResource/TableFlotaResources";
import MapGeneralResource from "../../components/ResourceScreenComponent/MapGeneralResource/MapGeneralResource";
import ChartCircleCaletaResource from "../../components/ResourceScreenComponent/ChartsResource/ChartCircleCaletaResource/ChartCircleCaletaResource";
import ChartCircleFlotaCategoriaResourceDesemb from "../../components/ResourceScreenComponent/ChartsResource/ChartCircleFlotaCategoriaResourceDesemb/ChartCircleFlotaCategoriaResourceDesemb";
import ChartCircleFlotaCategoriaResourceEmb from "../../components/ResourceScreenComponent/ChartsResource/ChartCircleFlotaCategoriaResourceEmb/ChartCircleFlotaCategoriaResourceEmb";
import ChartCircleResourceFlota from "../../components/ResourceScreenComponent/ChartsResource/ChartCircleFlotaResource/ChartCircleResourceFlota";
import ChartCircleRegionResource from "../../components/ResourceScreenComponent/ChartsResource/ChartCircleRegionResource/ChartCircleRegionResource";
import ChartLineFlotaResource from "../../components/ResourceScreenComponent/ChartsResource/ChartLineFlotaResource/ChartLineFlotaResource";
import ChartLinePescadoresCategoriasResource from "../../components/ResourceScreenComponent/ChartsResource/ChartLinePescadoresCategoriasResource/ChartLinePescadoresCategoriasResource";
import ChartLineYearResource from "../../components/ResourceScreenComponent/ChartsResource/ChartLineYearResource/ChartLineYearResource";
import TableFlotaEmbarcacionesResource from "../../components/ResourceScreenComponent/Tables/TableFlotaEmbarcacionesResource/TableFlotaEmbarcacionesResource";
import TableFlotaResourceCaleta from "../../components/ResourceScreenComponent/Tables/TableFlotaResourceCaleta/TableFlotaResourceCaleta";
import FloatingMenuResource from "../../components/ResourceScreenComponent/FloatingMenuRecource/FloatingMenuResource";
import TableFlotaResourceEspecie from "../../components/ResourceScreenComponent/Tables/TableFlotaResourceEspecie/TableFlotaResourceEspecie";
import TableFlotaResourceYear from "../../components/ResourceScreenComponent/Tables/TableFlotaResourceYear/TableFlotaResourceYear";
import TablePescadoresCategoriaAgrupadasResource from "../../components/ResourceScreenComponent/Tables/TablePescadoresCategoriaAgrupadasResource/TablePescadoresCategoriaAgrupadasResource";
import TablePescadoresResource from "../../components/ResourceScreenComponent/Tables/TablePescadoresResource/TablePescadoresResource";
import TableFlotaResourceRegion from "../../components/ResourceScreenComponent/Tables/TableFlotaResourceRegion/TableFlotaResourceRegion";
import ChartLineRegionResource from "../../components/ResourceScreenComponent/ChartsResource/ChartLineRegionResource/ChartLineRegionResource";

export default function ResourceScreen() {
  const { isLoader } = useContext(GlobalDataContext);
  const { especie } = useContext(ResourceDataContext); // Asegúrate de que region esté definido en ResourceDataContext
  const { selectedLetter } = useContext(ResourceDataContext); // Asegúrate de que region esté definido en ResourceDataContext
  return (
    <div className="container-resource-select-screens">
      {isLoader ? (
        <Loading />
      ) : (
        <div className="container-resource-select-0">
          {especie === "" ? (
            <>
              <AlphabetBar />
             {selectedLetter? <ListResource />:<></>} 
            </>
          ) : (
            <>
              <FloatingMenuResource />

              <div className="container-resource-info">
                <div className="container-resource-1">
                <MapGeneralResource />
                <div>
                <TableFlotaResourceEspecie />
                <ChartLineRegionResource/>
                </div>
          


                </div>

                <div className="container-resource-7">
                  <ChartCircleRegionResource />
                  <TableFlotaResourceRegion />
                </div>
                <div className="container-resource-1">
                  <TableFlotaResourceCaleta />
                  <ChartCircleCaletaResource />
                </div>
                <div className="container-resource-1">
                  <TablePescadoresResource />
                  <TablePescadoresCategoriaAgrupadasResource />
                </div>
                <div className="container-resource-1">
                  <ChartLinePescadoresCategoriasResource />
                </div>
                <div className="container-resource-1"></div>
                <div className="container-resource-2">
                  <ChartLineYearResource />
                  <TableFlotaResourceYear />
                </div>
                <div className="container-resource-3">
                  <ChartLineFlotaResource />
                </div>
                <div className="container-resource-4">
                  <ChartCircleResourceFlota />
                  <TableFlotaResources />
                </div>

                <div className="container-resource-5">
                  <TableFlotaEmbarcacionesResource />
                </div>
                <div className="container-resource-6">
                  <ChartCircleFlotaCategoriaResourceEmb />
                  <ChartCircleFlotaCategoriaResourceDesemb />
                </div>
         
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
