import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { HomeDataContext } from '../../../contexts/DataProviderHome';
import { isMobile } from 'react-device-detect';
import './MapGeneralStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

export default class MapGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      zoomLevel: 1.2, // Nuevo estado para manejar el zoom dinámico
    };
  }

  static contextType = HomeDataContext;

  componentDidMount() {
    const chileGeoJson = require('../../../data/regiones.json');
    echarts.registerMap('Chile', chileGeoJson);
    this.setState({ mapLoaded: true });

    this.handleResize(); // Setea el zoom inicial
    window.addEventListener('resize', this.handleResize); // Escucha cambios en el tamaño de pantalla
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize); // Limpia el listener al desmontar
  }

  handleResize = () => {
    const screenWidth = window.innerWidth;

    let newZoom = 1.6; // Default escritorio
   if (screenWidth < 1070) {
      newZoom = 1.2; // Tablets
    }

    this.setState({ zoomLevel: newZoom });
  };

  handleRegionClick = (params) => {
    const { setRegion, region } = this.context;
    if (isMobile) {
      setRegion(params.name);
    } else {
      if (params.name !== region) {
        setRegion(params.name); 
      } else {
        setRegion('');
      }
    }
    console.log(region);
  };
  
  resetRegionClick = () => {
    const { setRegion} = this.context;
    setRegion('');

  };

  render() {
    const { mapLoaded, zoomLevel } = this.state;
    const { filteredGeneralData, region } = this.context;

    if (!mapLoaded || !filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const allRegions = {};
    filteredGeneralData.forEach(item => {
      const key = `${item.year}_${item.flota}_${item.region_desembarque}_${item.nombre_region}`;
      allRegions[key] = {
        name: item.nombre_region,
        value: 0
      };
    });
  
    const groupedData = {};
    filteredGeneralData.forEach(item => {
      const key = `${item.year}_${item.flota}_${item.region_desembarque}_${item.nombre_region}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          name: item.nombre_region,
          value: isNaN(item.desembarque) ? 0 : item.desembarque
        };
      } else {
        groupedData[key].value += isNaN(item.desembarque) ? 0 : item.desembarque;
      }
    });
  
    const mapData = Object.values({ ...allRegions, ...groupedData });

    const option = {
      visualMap: {
        show: false,
        min: 0,
        max: Math.max(...mapData.map(region => region.value)),
        inRange: {
          color: [
            '#F0F8FF',
            '#B0E0E6',
            '#ADD8E6',
            '#87CEEB',
            '#87CEFA',
            '#00BFFF',
            '#7EC8E3',
            '#6495ED',
            '#4682B4',
            '#4169E1',
            '#0000FF',
            '#0000CD',
          ]
        },
        text: ['Max', 'Min'],
        calculable: true,
        textStyle: {
          color: 'black'
        },
        formatter: function (value) {
          return value.toLocaleString('es-CL', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          });
        }
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(',');
          return `${params.name}: ${formattedValue}`;
        }
      },
      series: [
        {
          name: 'DESEMBARQUE',
          type: 'map',
          roam: false,
          map: 'Chile',
          center: [-72, -36.675],
          zoom: zoomLevel, // Aquí aplicamos el zoom dinámico
          emphasis: {
            label: {
              show: true,
              color: 'green'
            },
            itemStyle: {
              areaColor: 'red',
              borderColor: 'black'
            }
          },
          data: mapData
        }
      ]
    };

    return (
      <div className='container-map-general'>
        <h2 className='title-map'>Región de desembarque</h2>
        <ReactECharts
          className='map-general'
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onChartReady={this.onChartReadyCallback}
          onEvents={{
            click: this.handleRegionClick,
          }}
          opts={option}
        />
      
        {/* Lanchas Transportadoras */}
        <div className={region === "Lanchas Transportadoras" ? "select-container-other-region" : 'container-other-region'} 
             onClick={() => this.handleRegionClick({ name: "Lanchas Transportadoras" })}>
          <FontAwesomeIcon className='logo-other-region' icon={faLocationDot} />
          <h4 className='text-other-region'>Lanchas Transportadoras</h4>
        </div>
    
        {/* Extranjero */}
        <div className={region === "Extranjero" ? "select-container-other-region" : 'container-other-region'} 
             onClick={() => this.handleRegionClick({ name: "Extranjero" })}>
          <FontAwesomeIcon className='logo-other-region' icon={faLocationDot} />
          <h4 className='text-other-region'>Extranjero</h4>
        </div>
      </div>
    );
  }
}
